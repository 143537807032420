<template>
  <ERow justify="center" align="stretch">
    <ECol cols="12" class="py-0">
      <v-data-table
        ref="usersTable"
        v-model="selected"
        :headers="headers"
        :items="items"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="admin-data-table"
        dense
        must-sort
        :loading="loading"
        loading-text="Loading..."
        calculate-widths
        :mobile-breakpoint="0"
        :options.sync="options"
        :server-items-length="total"
        item-key="id"
        fixed-header
        :footer-props="{
          'items-per-page-options': [50, 100, 500],
        }"
        :hide-default-footer="total <= 50"
      >
        <template #top>
          <v-container fluid class="py-0">
            <ERow>
              <ECol cols="6" class="pl-0 pb-0 text-h6"> Users </ECol>
              <v-spacer />
              <ECol cols="1" class="mb-1 pb-0 text-right">
                <CopyTableToClipboard
                  :headers="headers"
                  :items="filteredItems"
                />
              </ECol>
            </ERow>
          </v-container>
        </template>
        <template #item.fullname="{ item }">
          <span
            class="cursor-pointer primary--text"
            @click="openUserDialog(item.email)"
          >
            {{ item.fullname }}
          </span>
        </template>
        <template #item.lastLoginAt="{ item }">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ relativeDate(item.lastLoginAt) }}
              </span>
            </template>
            <span>
              {{ formatDate(item.lastLoginAt) }}
            </span>
          </v-tooltip>
        </template>
        <template #item.active="{ item }">
          <span class="d-flex justify-center">
            <v-icon :color="item.active ? 'green' : 'red'">
              {{ item.active ? "fa-check" : "fa-times" }}
            </v-icon>
          </span>
        </template>
      </v-data-table>
    </ECol>
  </ERow>
</template>

<script>
import companiesUsersTableHeaders from "@/components/companies/companiesUsersTableHeaders"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import { decamelize } from "humps"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useUserStore } from "@/stores/users"

export default {
  components: {
    CopyTableToClipboard,
  },
  props: {
    companyId: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      selected: [],
      items: [],
      headers: [],
      options: {},
      total: 0,
      loading: true,
      sortBy: "name",
      sortDesc: true,
    }
  },
  computed: {
    filteredItems() {
      let filteredItems = this.$refs.usersTable?.internalCurrentItems

      return filteredItems ? filteredItems : this.items
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchUsers()
      },
      deep: true,
    },
    companyId() {
      this.fetchUsers()
    },
  },
  async mounted() {
    this.headers = companiesUsersTableHeaders(this)
    await this.fetchUsers()
  },
  methods: {
    openUserDialog(email) {
      useUserStore().openDialog(email)
    },
    async fetchUsers() {
      if (!this.companyId) {
        return
      }
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        this.loading = true
        const params = {
          sort: `${decamelize(sortBy[0])}|${this.whichSort(sortDesc[0])}`,
          page: page,
          limit: itemsPerPage,
        }
        const response = await EvercamApi.companies.getCompanyUsers(
          this.companyId,
          {
            params,
          }
        )
        this.loading = false
        this.total = response.total
        this.items = response.items
      } catch (error) {
        this.$notifications.error({ text: "Could not load Users!", error })
      }
    },
    whichSort(type) {
      if (type) {
        return "desc"
      } else {
        return "asc"
      }
    },
  },
}
</script>
