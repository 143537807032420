<template>
  <ERow no-gutters class="item-details">
    <ECol v-for="(item, index) in details" :key="index">
      <v-list-item two-line class="px-0 mr-2">
        <v-list-item-content class="py-0 pt-1">
          <v-list-item-title class="caption font-weight-bold">{{
            item.label
          }}</v-list-item-title>
          <v-list-item-subtitle :class="item.class">
            <span v-if="item.label !== '# Active Users'">{{ item.value }}</span>
            <v-progress-linear
              v-else
              :value="activeUsersPercentage"
              color="primary"
              height="15"
            >
              {{ companyDetails.activeUsers }} /
              {{ companyDetails.users }}
            </v-progress-linear>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </ECol>
  </ERow>
</template>

<script>
export default {
  props: {
    companyDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    details() {
      return [
        { label: "# Cameras", value: this.companyDetails.cameras },
        { label: "# Projects", value: this.companyDetails.projects },
        { label: "# Sessions", value: this.companyDetails.sessions },
        {
          label: "# Active Users",
          value: this.companyDetails.activeUsers,
        },
      ]
    },
    activeUsersPercentage() {
      return (100 * this.companyDetails.activeUsers) / this.companyDetails.users
    },
  },
}
</script>

<style lang="scss">
.item-details {
  .v-list-item {
    min-height: 0px !important;
  }
}
</style>
