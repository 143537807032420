export default [
  {
    value: "name",
    text: "Camera Name",
    visible: true,
    width: 190,
    sortable: true,
  },
  {
    value: "exid",
    text: "Camera ID",
    visible: true,
    width: 150,
    sortable: true,
  },
  {
    value: "status",
    text: "Status",
    width: 150,
    visible: true,
    sortable: true,
  },
  {
    value: "ownerFullname",
    text: "Owner",
    width: 170,
    visible: true,
  },
  {
    value: "ownerEmail",
    text: "Email",
    width: 200,
    visible: true,
  },
  {
    value: "projectExid",
    text: "Project ID",
    width: 200,
    visible: true,
    sortable: true,
  },
  {
    value: "usersCount",
    text: "# Users",
    width: 200,
    visible: true,
    sortable: true,
  },
]
