<template>
  <v-card class="company-page">
    <v-card-title class="pa-0 pl-2">
      <ERow>
        <ECol cols="12" sm="12">
          <LogoDisplayer
            :domain="companyDetails.exid"
            :size="40"
            class="float-left mt-n1 mr-2"
          />
          <v-list-item-title>
            {{ companyDetails.name || companyId }}
            <a :href="`//${companyDetails.exid || companyId}`" target="_blank">
              {{ ` (${companyDetails.exid || companyId})` }}
            </a>
          </v-list-item-title>
          <v-divider class="mt-3" />
        </ECol>
        <ECol cols="12" md="12" sm="12" class="pt-0">
          <v-tabs v-model="currentTab">
            <v-tab key="tables"><v-icon left>fa-table</v-icon> Tables</v-tab>
            <v-tab key="graphs">
              <v-icon left>far fa-chart-bar</v-icon> Events
            </v-tab>
            <v-tab key="active_users">
              <v-icon left>fas fa-chart-line</v-icon> Active users
            </v-tab>
          </v-tabs>
        </ECol>
        <v-btn class="close-icon" color="primary" icon dark @click="goBack">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </ERow>
    </v-card-title>
    <v-card-text class="company-page__content">
      <v-tabs-items v-model="currentTab">
        <v-tab-item key="tables">
          <ERow no-gutters>
            <ECol :cols="$vuetify.breakpoint.lgAndUp ? 5 : 12">
              <CompaniesDetails :company-details="companyDetails" />
            </ECol>
          </ERow>
          <CompaniesProjectsTable :company-id="companyDetails.id" />
          <CompaniesCamerasTable :company-id="companyDetails.id" />
          <CompaniesUsersTable :company-id="companyDetails.id" />
          <ProjectHdds :company-id="companyDetails.id" />
        </v-tab-item>
        <v-tab-item key="graphs">
          <PaEventsStats :id="companyDetails.id" :events-of="'companies'" />
        </v-tab-item>
        <v-tab-item key="active_users">
          <PaActiveUsers :company-exid="companyDetails.exid" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import LogoDisplayer from "@evercam/shared/components/LogoDisplayer"
import CompaniesDetails from "@/components/companies/CompaniesDetails"
import CompaniesProjectsTable from "@/components/companies/CompaniesProjectsTable"
import CompaniesCamerasTable from "@/components/companies/CompaniesCamerasTable"
import CompaniesUsersTable from "@/components/companies/CompaniesUsersTable"
import ProjectHdds from "@/components/hdds/ProjectHdds"
import PaEventsStats from "@/components/pa/PaEventsStats"
import PaActiveUsers from "@/components/pa/PaActiveUsers"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useBreadcrumbStore } from "@/stores/breadcrumb"
import { mapStores } from "pinia"

export default {
  name: "Company",
  components: {
    LogoDisplayer,
    CompaniesDetails,
    CompaniesProjectsTable,
    CompaniesCamerasTable,
    CompaniesUsersTable,
    ProjectHdds,
    PaEventsStats,
    PaActiveUsers,
  },
  async asyncData({ params, error }) {
    return {
      error,
      companyId: params.id,
    }
  },
  data() {
    return {
      currentTab: 0,
      companyDetails: {},
    }
  },
  computed: {
    ...mapStores(useBreadcrumbStore),
  },
  async mounted() {
    let response
    try {
      response = await EvercamApi.companies.getPACompanyDetails(
        this.companyId,
        this.$route.query
      )
    } catch (e) {
      if ("with_zoho" in this.$route.query && e.response.status === 404) {
        this.error({
          statusCode: 404,
          message: "This account is not found in Evercam",
          homePageLink: false,
        })

        return
      } else if (e?.response?.status === 404) {
        this.error({
          statusCode: 404,
          message: "Company not found",
          homePageLink: true,
        })

        return
      } else {
        this.$notifications.error({
          text: "Load product analytics company details failed!",
          error: e,
        })
      }
    }

    const companyDetails = response

    if (companyDetails) {
      this.breadcrumbStore.breadcrumbs = [
        {
          icon: "fa-solid fa-home",
          href: "/",
        },
        {
          name: "Companies",
          href: "/companies",
        },
        {
          name: companyDetails.exid,
          icon: "far fa-building",
        },
      ]
    }

    this.companyDetails = companyDetails || {}
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@evercam/shared/styles/mixins";
@import "~vuetify/src/styles/styles.sass";
.company-page {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 48px);
  overflow: hidden;
  &__content {
    @include custom-scrollbar(rgb(186, 186, 186), rgb(231, 231, 231));
    overflow-x: hidden;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-height: calc(100vh - 93px);
  }
}
</style>
