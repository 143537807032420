export default (self) => [
  {
    value: "fullname",
    text: "Name",
    visible: true,
    width: 150,
  },
  {
    value: "email",
    text: "Email",
    width: 150,
    visible: true,
  },
  {
    value: "eventsCount",
    text: "# Actions",
    width: 150,
    visible: true,
  },
  {
    value: "lastLoginAt",
    text: "Date of last login",
    width: 150,
    visible: true,
    toStringFn: (item, key) => self.formatDate(item[key]),
  },
  {
    value: "active",
    text: "Is active user (7 days)",
    width: 150,
    visible: true,
  },
]
